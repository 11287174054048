<template>
	<div id="wrap">
			<div v-if="invitation!==null" :class="['wedding-wrap', invitation.invitationThemeColor, invitation.invitationThemeFont, invitation.invitationThemeFontSize]">
				<!--
					배경 : gray, pink, yellow, green
					폰트 : jejumyeongjo, nanumsquareneo, kimjungchulmyungjo, bookkmyungjo
					폰트 크기 : size1 - 크게, size2 - 더 크게
				// -->
				<div class="inner">
					

						
					<div class="img-div-view" v-if="invitation.purchase===null">
						<div class="info-txt warning">
							<p class="info"><span>미리보기용 청첩장입니다. <br />구매하지 않으면 7일 후 자동삭제됩니다.</span></p>
						</div>
					</div>
					
					<WritePreviewMain :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/>
					<template v-for="(sort,index) in sortList">

						<WritePreviewGreetingWord v-if="sort.value==='GreetingWord'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewTime v-if="sort.value==='time'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewAddress v-if="sort.value==='addrestransportation'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewGallery v-if="sort.value==='gallery'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewYoutube v-if="sort.value==='youtube'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewAttend v-if="sort.value==='attend'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewAccount v-if="sort.value==='account'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewGuestBook v-if="sort.value==='guestbook'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 

						<WritePreviewEnd v-if="sort.value==='end'" :key="index" :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/> 
					</template>
					<footer>
						<WritePreviewKakaoShareThumbnail :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/>
						<WritePreviewUrlShareThumbnail :invitation="invitation" :real="true" :scrollMotion="scrollMotion"/>
						<p class="copyright">Copyright&copy;2024. <span>VIVIDMARE</span> All rightsreserved.</p>
					</footer>
				</div>
			</div>

		
		<ContactPop v-show="popupFlag === 'contactPop'" :real="true"/>
		<AttendPop1 v-show="popupFlag === 'attendPop1'" :real="true"/>
		<AttendPop2 v-show="popupFlag === 'attendPop2'" :real="true"/>
		<AttendPop3 v-show="popupFlag === 'attendPop3'" :real="true"/>
		<AttendPop4 v-show="popupFlag === 'attendPop4'" :real="true"/>
		<GuestBookWritePop v-show="popupFlag === 'guestBookWritePop'" :real="true"/>
		<GuestBookDeletePop v-show="popupFlag === 'guestBookDeletePop'" :real="true"/>
		<ImageExtendsViewPop v-show="popupFlag=== 'imageExtendsViewPop'" :real="true"/>
		
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import WritePreviewMain from "@/components/write/preview/components/WritePreviewMain.vue";
import WritePreviewGreetingWord from "@/components/write/preview/components/WritePreviewGreetingWord.vue";
import WritePreviewTime from "@/components/write/preview/components/WritePreviewTime.vue";

import WritePreviewAddress from "@/components/write/preview/components/WritePreviewAddress.vue";

import WritePreviewGallery from "@/components/write/preview/components/WritePreviewGallery.vue";
import WritePreviewYoutube from "@/components/write/preview/components/WritePreviewYoutube.vue";
import WritePreviewAttend from "@/components/write/preview/components/WritePreviewAttend.vue";
import WritePreviewAccount from "@/components/write/preview/components/WritePreviewAccount.vue";
import WritePreviewGuestBook from "@/components/write/preview/components/WritePreviewGuestBook.vue";
import WritePreviewEnd from "@/components/write/preview/components/WritePreviewEnd.vue";

import WritePreviewKakaoShareThumbnail from "@/components/write/preview/components/WritePreviewKakaoShareThumbnail.vue";
import WritePreviewUrlShareThumbnail from "@/components/write/preview/components/WritePreviewUrlShareThumbnail.vue";


import ContactPop from "@/components/popup/ContactPop.vue";
import AttendPop1 from "@/components/popup/AttendPop1.vue";
import AttendPop2 from "@/components/popup/AttendPop2.vue";
import AttendPop3 from "@/components/popup/AttendPop3.vue";
import AttendPop4 from "@/components/popup/AttendPop4.vue";
import GuestBookWritePop from "@/components/popup/GuestBookWritePop.vue";
import GuestBookDeletePop from "@/components/popup/GuestBookDeletePop.vue";
import ImageExtendsViewPop from "@/components/popup/ImageExtendsViewPop.vue";



export default {
  name: 'WritePreview',
  props:[],
  components: {WritePreviewMain,WritePreviewGreetingWord,WritePreviewTime,WritePreviewAddress,WritePreviewGallery,WritePreviewYoutube,WritePreviewAttend,WritePreviewAccount,WritePreviewGuestBook,
  WritePreviewEnd,WritePreviewKakaoShareThumbnail,WritePreviewUrlShareThumbnail,
  
 ContactPop,AttendPop1,AttendPop2,AttendPop3,AttendPop4,GuestBookWritePop,GuestBookDeletePop,ImageExtendsViewPop },
  computed:{
	sortList(){
		if(this.invitation!=null)
			return JSON.parse(this.invitation.invitationLayoutSortJson);
		else
			return [];
	},
	scrollMotion(){
		if(this.invitation!==null && this.invitation.invitationThemeAppearYn === 'Y'){
			return 'scroll-motion'
		}
		return '';
	}
  },
  data(){
    return {
		popupFlag:null,
		invitation:null,
		invitationAesId:this.$route.query.invitationAesId ? this.$route.query.invitationAesId : null,
    }
  },
  
  created(){
	const context =this
	const parameter ={
		invitationAesId: this.invitationAesId
	}
	this.$store.dispatch("REQUEST_EXTENAL_INVITATION_SELECT",parameter).then((data) => {
			if (data.data !== undefined && data.data!==null) {
				context.invitation =data.data
				if(context.invitation.invitationAttendYn === 'Y' &&  context.invitation.invitationAttendPopupYn === 'Y'){
					const param = {
						flag: 'attendPop1',
						invitation: context.invitation
					}
					EventBus.$emit('layerPopup',param)
				}


				if(context.invitation.invitationThemeExpansionYn !== 'Y'){
					context.setScalable(true)
				}else{
					context.setScalable(false)
					
				}

			} 
	})
  },
  mounted(){
	
	const context = this
    EventBus.$on("layerPopup", (param) => {
      EventBus.$emit('popupInit',param);
		  context.popupFlag =  param.flag;
	});
    EventBus.$on("popupClose", () => {
		  context.popupFlag =  null;
	});
  },
  beforeDestroy(){
    EventBus.$off('layerPopup')
    EventBus.$off('popupClose')
  },
  methods: {

	setScalable(scalable) {
        let viewport = document.querySelector('meta[name="viewport"]'),
            content = scalable ?
                'width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes' :
                'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no';

        viewport.setAttribute('content', content)

        //forceReflow()
    }
  },

}
</script>
